<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="44"
      cy="43.5"
      r="36"
      fill="#F9FAFB"
    />
    <circle
      cx="43.5"
      cy="44"
      r="29.5"
      fill="#F0F1F3"
    />
    <path
      d="M51.4261 48.3679H50.0687L49.5876 47.9048C51.2715 45.9494 52.2852 43.4108 52.2852 40.6492C52.2852 34.4914 47.2852 29.5 41.1168 29.5C35.2406 29.5 30.4296 34.0283 30 39.7916H33.4708C33.9003 35.9322 37.1306 32.9305 41.1168 32.9305C45.3952 32.9305 48.8488 36.3782 48.8488 40.6492C48.8488 44.9202 45.3952 48.3679 41.1168 48.3679C40.8247 48.3679 40.5498 48.3165 40.2577 48.2822V51.747C40.5498 51.7813 40.8247 51.7985 41.1168 51.7985C43.8832 51.7985 46.4261 50.7864 48.3849 49.1055L48.8488 49.5858V50.9408L57.4399 59.5L60 56.9443L51.4261 48.3679Z"
      fill="#6D7883"
    />
    <path
      d="M29.75 51.375H32.25V42.625H29.75V51.375ZM34.75 42.625V51.375H37.25V42.625H34.75Z"
      fill="#6D7883"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcSearchPauseEnabled',
  props: {
    size: {
      type: [String, Number],
      default: 88,
    },
  },
}
</script>
